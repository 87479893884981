<template>
  <v-carousel
    cycle
    :interval="interval"
    :height="wdHeight"
    :hide-delimiters="hideDelimiters"
    :show-arrows="showArrows"
    show-arrows-on-hover
    hide-delimiter-background
  >
    <v-carousel-item
      v-for="(item, index) in items"
      :key="index"
    >
      <v-hover v-slot="{ hover }">
        <v-img
          height="100%"
          :src="item.cover"
        >
          <div class="d-flex justify-end mt-10 mr-12">
            <v-btn
              v-if="hover"
              text
              dark
              x-large
              class="btn-set-bg"
              @click="onAction(item)"
            >
              {{ btnSet.text }}
            </v-btn>
          </div>
        </v-img>
      </v-hover>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
  import api from '@/api/co.api'
  import app from '@/api/co.app'
  import user from '@/api/co.user'
  import web from '@/api/web/co.web'

  export default {
    name: 'UserBanner',
    props: {
      height: {
        type: [Number, String],
        default: 720
      },
      points: {
        type: Object,
        default: () => {
          return {
            xs: 200,
            sm: 320,
            md: 410,
            lg: 720,
            xl: 800
          }
        }
      },
      cycle: {
        type: Boolean,
        default: true
      },
      interval: {
        type: Number,
        default: 5000
      }
    },
    data () {
      return {
        hideDelimiters: true,
        showArrows: false,
        items: [],
        userInfo: {},
        btnSet: {
          text: '设置封面'
        }
      }
    },
    computed: {
      wdHeight () {
        const name = this.$vuetify.breakpoint.name
        const ht = this.points[name] || this.height
        return ht
      },
    },
    created () {
      this.getCover()
    },
    methods: {
      onAction (item) {
        this.$emit('action', item)
      },
      reset (ev) {
        if (ev.backgroundImage) {
          this.items = []
          this.items.push({
            cover: ev.backgroundImage
          })
        }
      },
      getCover () {
        const me = this
        let params = null

        me.userInfo = user.info.get()

        me.items = []
        me.hideDelimiters = true
        me.showArrows = false

        if (me.userInfo.backgroundImage) {
          me.items.push({
            cover: me.userInfo.backgroundImage
          })
        } else {
          const executed = function (res) {
            for (const i in params.items) {
              const item = params.items[i]
              me.items.push({
                cover: item.cover
              })
            }

            if (me.items.length < 1) {
              me.items.push({
                cover: app.pic.cover_my
              })
            }

            if (me.items.length > 1) {
              me.hideDelimiters = false
              me.showArrows = true
            }
          }

          params = web.banner.getParams({
            type: web.comm.BannerTypes.MY_MAJOR,
            state: web.comm.States.PUBLISH,
            executed
          })

          api.httpx.getItems(params)
        }
      }
    }
  }
</script>

<style lang="scss">
.btn-set-bg {
  background-color: rgba(0, 0, 0, 0.3) !important;
}
</style>
